import { Nav , NavDropdown  } from 'react-bootstrap';
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../slide/imagessss.png";
import Navbar from 'react-bootstrap/Navbar';


function HeaderDetails(){
    return(
       
                        <div className='subhead' style={{paddingBottom:'1vw',paddingTop:'1vw'}} >
                            <Container fluid >
                                <Row style={{}} className=''>
                                
                                    <Col  className='p-0' style={{margin:'auto'}}>
                                    
                                                <Navbar expand="lg" className="bg-body-tertiary p-0"  style={{marginLeft:'2vw'}}>
                                                    <Container fluid style={{backgroundColor:'white'}} className=''>
                                                        <Navbar.Brand href="./" style={{marginRight:'3vw'}}> <img src={Logo} alt="Logo" style={{}} /></Navbar.Brand>
                                                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                                        <Navbar.Collapse id="basic-navbar-nav" >
                                                       
                                                                <Nav className="me-auto" style={{float:'right', fontSize:'15px',fontWeight:'550'}}>
                                                                    <Nav.Link href="/flights"  id='linkcp' style={{marginRight:'15px'}}>
                                                                        <svg style={{marginRight:"8px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-plane"><path d="M17.8 16.2 16 11l3.5-3.5C21 6 21.5 4 21 3c-1-.5-3 0-4.5 1.5L13 8 4.8 6.2c-.5-.1-.9.1-1.1.5l-.3.5c-.2.5-.1 1 .3 1.3L9 12l-2 3H4l-1 1 3 2 2 3 1-1v-3l3-2 3.5 5.3c.3.4.8.5 1.3.3l.5-.2c.4-.3.6-.7.5-1.2z"/></svg>
                                                                        
                                                                        Flights
                                                                    </Nav.Link>
                                                                  
                                                                    <Nav>
                                                                        <Nav.Link href="/hotels" id='linkcp' >
                                                                            <svg style={{ marginRight: "8px" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-bed">
                                                                                <path d="M2 4v16"/>
                                                                                <path d="M2 8h18a2 2 0 0 1 2 2v10"/>
                                                                                <path d="M2 17h20"/>
                                                                                <path d="M6 8v9"/>
                                                                            </svg>
                                                                            Accommodation
                                                                        </Nav.Link>
                                                                        <NavDropdown title="" id="basic-nav-dropdown">
                                                                            <NavDropdown.Item href="/hotels">Hotels</NavDropdown.Item>
                                                                            <NavDropdown.Item href="/apartments">Apartments</NavDropdown.Item>
                                                                            <NavDropdown.Item href="/villas">Villas</NavDropdown.Item>
                                                                        </NavDropdown>
                                                                    </Nav>

                                                                    <Nav>
                                                                        <Nav.Link href="/activities" id='linkcp' >
                                                                            <svg style={{ marginRight: "8px" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-roller-coaster">
                                                                                <path d="M6 19V5"/>
                                                                                <path d="M10 19V6.8"/>
                                                                                <path d="M14 19v-7.8"/>
                                                                                <path d="M18 5v4"/>
                                                                                <path d="M18 19v-6"/>
                                                                                <path d="M22 19V9"/>
                                                                                <path d="M2 19V9a4 4 0 0 1 4-4c2 0 4 1.33 6 4s4 4 6 4a4 4 0 1 0-3-6.65"/>
                                                                            </svg>
                                                                            Activities
                                                                        </Nav.Link>
                                                                        <NavDropdown title="" id="activities-dropdown">
                                                                            <NavDropdown.Item href="/city-tour">City Tour</NavDropdown.Item>
                                                                            <NavDropdown.Item href="/one-day-visit">One-Day Visit</NavDropdown.Item>
                                                                            <NavDropdown.Item href="/multi-day-visit">Multi-Day Visit</NavDropdown.Item>
                                                                            <NavDropdown.Item href="/stadium-entry">Stadium Entry</NavDropdown.Item>
                                                                            <NavDropdown.Item href="/museum-visit">Museum Visit</NavDropdown.Item>
                                                                        </NavDropdown>
                                                                    </Nav>

                                                                    <Nav>
                                                                        <Nav.Link href="/transferts" id='linkcp' >
                                                                            <svg style={{ marginRight: "8px" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-arrow-left-right">
                                                                                <path d="M8 3 4 7l4 4"/>
                                                                                <path d="M4 7h16"/>
                                                                                <path d="m16 21 4-4-4-4"/>
                                                                                <path d="M20 17H4"/>
                                                                            </svg>
                                                                            Transportations
                                                                        </Nav.Link>
                                                                        <NavDropdown title="" id="transportations-dropdown">
                                                                            <NavDropdown.Item href="/transfer">Transfer</NavDropdown.Item>
                                                                            <NavDropdown.Item href="/bus">Bus</NavDropdown.Item>
                                                                            <NavDropdown.Item href="/taxi">Taxi</NavDropdown.Item>
                                                                            <NavDropdown.Item href="/train">Train</NavDropdown.Item>
                                                                        </NavDropdown>
                                                                    </Nav>

                                                                   
                                                                    <Nav>
                                                                        <Nav.Link href="/cars" id='linkcp' >
                                                                            <svg style={{ marginRight: "8px" }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-car-front">
                                                                                <path d="m21 8-2 2-1.5-3.7A2 2 0 0 0 15.646 5H8.4a2 2 0 0 0-1.903 1.257L5 10 3 8"/>
                                                                                <path d="M7 14h.01"/>
                                                                                <path d="M17 14h.01"/>
                                                                                <rect width="16" height="8" x="3" y="10" rx="2"/>
                                                                                <path d="M5 18v2"/>
                                                                                <path d="M19 18v2"/>
                                                                            </svg>
                                                                            Rental
                                                                        </Nav.Link>
                                                                        <NavDropdown title="" id="rental-dropdown">
                                                                            <NavDropdown.Item href="/car">Car</NavDropdown.Item>
                                                                            <NavDropdown.Item href="/bus">Bus</NavDropdown.Item>
                                                                            <NavDropdown.Item href="/private-jet">Private Jet</NavDropdown.Item>
                                                                        </NavDropdown>
                                                                    </Nav>
                                                                    <Nav.Link href="/packages"  id='linkcp' style={{marginRight:'15px'}}>
                                                                        <svg style={{marginRight:"8px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-map"><polygon points="3 6 9 3 15 6 21 3 21 16 15 21 9 16 3 21"/><line x1="9" x2="9" y1="3" y2="16"/><line x1="15" x2="15" y1="6" y2="21"/></svg>
                                                                        
                                                                        Packages
                                                                    </Nav.Link>
                                                                  
              
                                                                   
                                                                   
                                                                    
                                                                    
                                                                       
                                                                </Nav>
                                                                
                                                        </Navbar.Collapse>
                                                    </Container>
                                                </Navbar>
                                                



                                    </Col>

                                </Row>
                            </Container>
                        </div>
       
       
        

       
    )
}

export default HeaderDetails; 