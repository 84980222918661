import SubHeader from './SubHeader'
import Header from './Header'

import { useTranslation } from 'react-i18next'

import Carousel from 'react-bootstrap/Carousel';
import Video6 from './Video6';

import SD from "../../slide/977.png";
import SD1 from "../../slide/99977.png";
import SD2 from "../../slide/67m.png";
import SD3 from "../../slide/66m.png";
import sd11 from "../../slide/elc.jpg";
import sd12 from "../../slide/blog-05.jpg";
import sd16 from "../../slide/54m.png";
import sd14 from "../../slide/visits.png";
import sd15 from "../../slide/visiiii.png";
import sd13 from "../../slide/visii.png";
import Button from 'react-bootstrap/Button';
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import NewFoot from './NewFoot'




function Home() {

    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };

    return (

        <div className='w-100'>
            <SubHeader />
            <Header />

            <Carousel style={{marginBottom:'4vw'}}>
            <div style={{height:'40vw'}}>
        
                <Video6 />
                <Carousel.Caption style={{marginTop:'-35vw'}}>
                        <div className="">
                            
                            <h2 className="h3cc">{t('hotelidh')} </h2>
                            <span className="spancf" >{t('hotelidhp')} </span>

                        </div>
                
                </Carousel.Caption>
            </div>
            
    
            </Carousel>

            <div className="qsd1111">
            <Container style={{paddingTop:'6vw',paddingBottom:'4vw'}}>
                <Row>
                    <Col xs="12"  xl="6">
                        <img className="img-fluid" src={SD}  alt="hotelidh" />
                    </Col>
                    <Col  xs="12"  xl="6" className="j1" style={{ marginTop:'1vw',display:'flex', flexDirection:'column'}}>   
                        <div style={{marginBottom:'26px'}}>
                            <p className="joinp" style={{color:'#1a6976',textAlign:'center',fontSize:'14px'}}>{t('b2b')}</p>
                            <h2 className="h2join2" style={{textAlign:'center',lineHeight:'90px'}}>{t('aio')}  <br/>{t('bp')} </h2>
                          
                        </div> 
                        <div style={{marginBottom:'32px',paddingLeft:'0vw', display:'flex',flexDirection:'row'}}>
                            <p className="joinp1" style={{padding:'1vw',textAlign:'center'}}>
                                {t('ho1')} <br/>  
                                {t('ho2')} <br/>
                                {t('ho3')} <br/> 
                                {t('ho4')}<br/>
                                {t('ho5')}
                            </p>
                        </div>
                            <>
                                    <style type="text/css">
                                        {`
                                    .btn-flat {
                                    color: white;
                                   
                                    }

                                    .btn-xxl {
                                    padding: 1rem 1.5rem;
                          
                                    background-color:#772828;
                                    padding: 8px 40px;
                                    border-radius: 0px;
                                    font-family: 'Cormorant Garamond';
                                    
                                    }

                                    `}
                                    </style>

                                    <Button id="btnid" style={{padding:'10px',margin:'auto', fontSize:'0.9rem', width:'25%',fontWeight:'100',letterSpacing:'1.5px',border:'none'}}   size="xxl"  >
                                       <a href="about" className="text-white">{t('wwa')} </a>
                                    </Button>
                            </>
                       
                        
                    </Col>
                </Row>
            </Container>
            </div>
            
            <div className="" style={{}}>
            <Container style={{paddingTop:'4vw',paddingBottom:'4vw',display:'flex',flexDirection:'column'}}>
                <Row>
                    

                    <Col sm={12} className="j1" style={{ marginTop:'1vw'}}>   
                        <div style={{marginBottom:'26px'}}>
                           
                            <h2 className="h2join" style={{textAlign:'center',fontSize:'55px',letterSpacing:'2px'}}>
                            {t('eyb')}  <br/>{t('bpw')} </h2>
                        </div> 
                        <div style={{marginBottom:'32px',float:'right',paddingLeft:'12vw',paddingRight:'12vw', display:'flex',flexDirection:'row'}}>
                            <p className="joinp1" style={{padding:'1vw',textAlign:'center'}}> {t('hotelidhtxt')} </p>
                        </div>
                            
                    </Col>
                    <Col  className="d-flex justify-content-center align-items-center">
                        <img className="img-fluid" src={SD1}  alt="hotelidh"  />
                    </Col>
                </Row>
            </Container>
            </div>
           
           
            <div className="" >
            <Container style={{paddingTop:'6vw',paddingBottom:'8vw'}}>
                <Row>
                    <Col  xs="12"  xl="6" className="d-flex justify-content-center align-items-center">
                        <img className="img-fluid" src={SD2}  alt="hotelidh" />
                    </Col>

                    <Col  xs="12"  xl="6"  className="j1" style={{marginTop:'5vw'}}>   
                        <div style={{marginBottom:'26px',paddingLeft:'3vw'}}>
                            <p className="joinp" style={{color:'#861e12',fontSize:'20px',marginBottom:'1rem'}}>{t('aoh')}</p>

                            <h2 className="h2join">{t('cj')}<br/>{t('oc')}</h2>
                        
                        </div> 
                        <div style={{marginBottom:'34px'}}>
                            <p className="joinp1" style={{paddingLeft:'3vw'}}>{t('ocp')}<br/> <br/>{t('oct')}</p>
                        </div>
                        <>
                                    

                                    <Button href="/Join" size="xxl" id="boutidh"  style={{
                                        float: 'left',
                                        marginLeft: '3vw',
                                        padding: '20px 40px 20px 40px',
                                        width: '40%',
                                        letterSpacing: '2px',
                                        fontFamily: 'Cormorant Garamond',
                                        fontSize: '16px',
                                        border:'none',
                                        boxShadow: '0 0 0 1px #d9d0bd,0 2px 48px 0 rgba(0,0,0,.04) !important',
                                        
                                    }}  >
                                        {t('joinus')}
                                    </Button>
                                    </>
                       
                        
                    </Col>
                </Row>
            </Container>
            </div>
            
            <div className="">
            <Container style={{paddingBottom:'4vw'}}>
                <Row>
                    <Col xs="12"  xl="6" className="j1" style={{marginTop:'2vw',marginBottom:'1vw'}}>   
                        <div style={{marginBottom:'26px'}}>
                            <p className="joinp" style={{color:'#861e12',fontSize:'20px',marginBottom:'1rem'}}>{t('dos')}</p>

                            <h2 className="h2join">{t('uoo')}<br/>{t('os')}</h2>
                        </div> 
                        <div style={{marginBottom:'34px'}}>
                            <p className="joinp1" style={{paddingLeft:'0vw'}} >{t('osp')} <br/> <br/> {t('ost')}</p>
                        </div>
                                <>
                                    <style type="text/css">
                                        {`
                                    .btn-flat {
                                   
                                    color: #fff;
                                   
                                 
                                    }

                                    .btn-xxl {
                                    padding: 1rem 1.5rem;
                                    font-size: 1.09rem;
                                    background-color:#772828;
                                    padding: 20px 40px 20px 40px;
                                    border-radius: 0px;
                                    }
                                    `}
                                    </style>

                                    <Button href="/Services" size="xxl" id="boutidh"  style={{
                                        float: 'left',
                                        padding: '20px 40px 20px 40px',
                                        width: '35%',
                                        letterSpacing: '2px',
                                        fontFamily: 'Cormorant Garamond',
                                        fontSize: '16px',
                                       
                                        
             
                                        boxShadow: '0 0 0 1px #d9d0bd,0 2px 48px 0 rgba(0,0,0,.04) !important',
                                        
                                    }}  >
                                        {t('ss')}
                                    </Button>
                                </>
                       
                        
                    </Col>
                    <Col xs="12"  xl="6" className="d-flex justify-content-center align-items-center">
                        <img className="img-fluid" src={SD3}  alt="hotelidh" />
                    </Col>
                </Row>
            </Container>
            </div>

            <div className="home6" style={{position:'relative',zIndex:'100',marginTop:'-3vw'}}>
                    <div style={{marginBottom:'26px'}}>
                        <h2 className="h2join" style={{textAlign:'center',fontSize:'55px',paddingTop:'5vw',color:'#1e2031',letterSpacing:'2px'}}>{t('jg')}<br/>{t('osb')}</h2>
                    </div> 
                    
                    <div style={{marginBottom:'32px',float:'right',paddingLeft:'12vw',paddingRight:'12vw', display:'flex',flexDirection:'row'}}>
                            <p className="joinp1" style={{padding:'1vw',textAlign:'center'}}>{t('h6p')} <br/>{t('h6p1')}</p>
                    </div>
          
                    <Container className="scm1" >
                        <Row>
                            <Col  xl="4" lg="6" style={{display:'flex', flexDirection:'column',paddingLeft:'50px',paddingBottom:'50px'}}>
                                <div className="jfi">
                                            <img src={sd11} alt="hotelidh" className="jimg" />
                                </div>
                                
                                <div className="divdimg">
                                    <h2 className="h2s2">{t('cmp')}</h2>
                                    <p className="pservice2">{t('cmpt')}</p>
                                    <>
                                            <style type="text/css">
                                                {`
                                            .btn-flat {
                                            color: white;
                                        
                                        
                                            }

                                            .btn-xxl {
                                            padding: 1rem 1.5rem;
                                            font-size: 1.5rem;
                                            background-color:#772828;
                                            padding: 8px 40px;
                                            border-radius: 0px;
                                        
                                            }

                                            `}
                                            </style>

                                            <Button className="hov" style={{padding:'7px',float:'left',fontSize:'1rem', width:'50%',fontWeight:'600',letterSpacing:'1px',border:'none'}} size="xxl"  >
                                            <a href="https://www.elcomparador-b2b.com/" className="text-white"> {t('rm')} </a> 
                                            </Button>
                                    </>
                                </div>
                                            
                            
                            </Col>
                            <Col  xl="4" lg="6" style={{display:'flex', flexDirection:'column',paddingLeft:'50px',paddingBottom:'50px'}}>
                            <div>
                                    <div className="ji" style={{padding:'10px'}}>
                                                        <div >
                                                                        <div className="jfi">
                                                                            <img src={sd12} alt="hotelidh" className="jimg" />
                                                                        </div>
                                                        </div>
                                                        <div className="mlk" style={{display:'flex', flexDirection:'column',paddingBottom:'60px',fontSize:'20px',fontFamily:'Cormorant Garamond',fontWeight:'1'}}>
                                                            <a href="http://groupidh.com/" style={{margin:'auto'}}> <h3 className="mlh3" style={{fontSize:'20px',fontFamily:'Cormorant Garamond',fontWeight:'0',textAlign:'center'}}>groupidh.com</h3></a> 
                                                            <svg xmlns="http://www.w3.org/2000/svg" style={{margin:'auto',color:'white'}} width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-link"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"/><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"/></svg>
                                                                        
                                                                        
                                                        </div>
                                    </div>
                                </div>
                            
                            </Col>
                            <Col  xl="4" lg="6" style={{display:'flex', flexDirection:'column',paddingLeft:'50px',paddingBottom:'50px'}}>
                                <div className="jfi">
                                            <img src={sd13} alt="hotelidh" className="jimg" />
                                </div>
                                
                                <div className="divdimg">
                                    <h2 className="h2s2">{t('idhtours')}</h2>
                                    <p className="pservice2">{t('idhtoursp')}</p>
                                    <>
                                            <style type="text/css">
                                                {`
                                            .btn-flat {
                                            color: white;
                                        
                                        
                                            }

                                            .btn-xxl {
                                            padding: 1rem 1.5rem;
                                            font-size: 1.5rem;
                                            background-color:#772828;
                                            padding: 8px 40px;
                                            border-radius: 0px;
                                        
                                            }

                                            `}
                                            </style>

                                            <Button className="hov" style={{padding:'7px',float:'left',fontSize:'1rem', width:'50%',fontWeight:'600',letterSpacing:'1px'}} variant="flat" size="xxl"  >
                                                <a href="http://srv2.elcomparador-b2b.com/hotels" className="text-white"> {t('rm')} </a>
                                            </Button>
                                            </>
                                </div>
                                            
                            
                            </Col>
                            <Col  xl="4" lg="6" style={{display:'flex', flexDirection:'column',paddingLeft:'50px',paddingBottom:'50px'}}>
                            <div>
                                    <div className="ji" style={{padding:'10px'}}>
                                                        <div >
                                                                        <div className="jfi">
                                                                            <img src={sd14} alt="hotelidh" className="jimg" />
                                                                        </div>
                                                        </div>
                                                        <div className="mlk" style={{display:'flex', flexDirection:'column',paddingBottom:'60px',fontSize:'20px',fontFamily:'Cormorant Garamond',fontWeight:'1'}}>
                                                            <a href="https://www.elcomparador-b2b.com/" style={{margin:'auto'}}> <h3 className="mlh3" style={{fontSize:'20px',fontFamily:'Cormorant Garamond',fontWeight:'0'}}>www.elcomparador-b2b.com</h3></a> 
                                                                <svg xmlns="http://www.w3.org/2000/svg" style={{margin:'auto',color:'white'}} width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-link"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"/><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"/></svg>
                                                                        
                                                                        
                                                        </div>
                                    </div>
                                </div>
                            
                            </Col>
                            <Col  xl="4" lg="6" className="rescc" style={{display:'flex', flexDirection:'column',paddingLeft:'50px',paddingBottom:'50px'}}>
                                <div className="jfi">
                                            <img src={sd15} alt="hotelidh" className="jimg" />
                                </div>
                                <a href="http://groupidh.com/"> </a> 
                                <div className="divdimg">
                                    <h2 className="h2s2">{t('extranet')}</h2>
                                    <p className="pservice2">{t('extranetp')}</p>
                                    <>
                                            <style type="text/css">
                                                {`
                                            .btn-flat {
                                            color: white;
                                        
                                        
                                            }

                                            .btn-xxl {
                                            padding: 1rem 1.5rem;
                                            font-size: 1.5rem;
                                            background-color:#772828;
                                            padding: 8px 40px;
                                            border-radius: 0px;
                                        
                                            }

                                            `}
                                            </style>

                                            <Button className="hov" style={{padding:'7px',float:'left',fontSize:'1rem', width:'50%',fontWeight:'600',letterSpacing:'1px'}} variant="flat" size="xxl"  >
                                                <a href="http://groupidh.com/" className="text-white"> {t('rm')}</a>
                                            </Button>
                                            </>
                                </div>
                                        
                            
                            </Col>
                            <Col  xl="4" lg="6" className="r7" style={{display:'flex', flexDirection:'column',paddingLeft:'50px',paddingBottom:'50px'}}>
                                <div className="jfi">
                                            <img src={sd16} alt="hotelidh" className="jimg" />
                                </div>
                                
                                <div className="divdimg">
                                    <h2 className="h2s2">{t('corpo')}</h2>
                                    <p className="pservice2">{t('corpt')}</p>
                                    <>
                                            <style type="text/css">
                                                {`
                                            .btn-flat {
                                            color: white;
                                            
                                        
                                            }

                                            .btn-xxl {
                                            padding: 1rem 1.5rem;
                                            font-size: 1.5rem;
                                            background-color:#772828;
                                            padding: 8px 40px;
                                            border-radius: 0px;
                                        
                                            }

                                            `}
                                            </style>

                                            <Button className="hov" style={{padding:'7px',float:'left',fontSize:'1rem', width:'50%',fontWeight:'600',letterSpacing:'1px'}} variant="flat" size="xxl"  >
                                                <a href="https://www.idhcorporate.com/corporate" className="text-white" > {t('rm')}</a>
                                            </Button>
                                            </>
                                </div>
                                            
                            
                            </Col>
                        </Row>
                    </Container>
            </div>

            <NewFoot />
          
            
        </div>

    );
}

export default Home;