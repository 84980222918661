
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SD from "../slide/6588.jpg";
import { useTranslation } from 'react-i18next'
import Header from "./home/Header";
import SubHeader from "./home/SubHeader";
import NewFoot from "./home/NewFoot";
import SDK from "../slide/fgfgg.jpg";
import dl from '../slide/service.png';
import dl1 from '../slide/ka.jpg';
import dl2 from '../slide/pol.png';
import dl3 from '../slide/98.jpg';
import dl33 from '../slide/vrtt.png';
function About(){

    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };
    return(
        <div>
            <SubHeader />
            <Header />

            <div className="About1">
                <div className="ContactH1">
                    <div className="ContactH11">
                        <div className="ContactH111">
                            <span className="spanc">  {t('Discover')}</span>
                            <h2 className="h3c">{t('Discover1')}</h2>
                        </div>

                    </div>

                </div>
                
          
            </div>
            <div className="qsd1">
            <Container style={{paddingTop:'6vw',paddingBottom:'4vw'}}>
                <Row>
                    <Col xs="12"  xl="6" >
                        <img src={SD} className="img-fluid"  alt="hotelidh" />
                    </Col>
                    <Col xs="12"  xl="6" className="j1" style={{marginTop:'7vw'}}>   
                        <div style={{marginBottom:'26px'}}>
                            <p className="joinp" style={{color:'#1a6976'}}>{t('about')}</p>

                            <h2 className="h2join">{t('about1')}  <br/>{t('about2')}</h2>
                 
                        </div> 
                        <div style={{marginBottom:'32px'}}>
                            <p className="joinp1" style={{paddingLeft:'0vw'}}>{t('about3')}</p>
                        </div>
                        
                    </Col>
                </Row>
            </Container>
            </div>
            <div style={{marginTop:'5vw',backgroundAttachment:'fixed'}} className='idhj3'>
           
           <section className="services-2" style={{marginTop:'6vw'}}>
				<Container className="container">
					<Row className="row" id="cgcg">
								<Col sm="12" >
										<h1 className="vde" style={{fontWeight:'500',letterSpacing:'2px',fontSize:'60px',textAlign:'center'}}>{t('why')}</h1>
										<h3 className="pde" style={{textAlign:'center'}}>{t('why1')}</h3>
								</Col>
								<Col sm="12"   className="colmd12">
										<span style={{textAlign:'center',color:'white'}}>
											<svg  xmlns="http://www.w3.org/2000/svg" color="#861e12" width="50" height="50" viewBox="0 0 24 24" 
												fill="none" stroke="currentColor" strokewidth="2" strokelinecap="round" 
												strokelinejoin="round" className="lucide lucide-quote"><path 
												d="M3 21c3 0 7-1 7-8V5c0-1.25-.756-2.017-2-2H4c-1.25 0-2 .75-2 1.972V11c0 1.25.75 2 2 2 1 0 1 0 1 1v1c0 1-1 2-2 2s-1 .008-1 1.031V20c0 1 0 1 1 1z"/><path d="M15 21c3 0 7-1 7-8V5c0-1.25-.757-2.017-2-2h-4c-1.25 0-2 .75-2 1.972V11c0 1.25.75 2 2 2h.75c0 2.25.25 4-2.75 4v3c0 1 0 1 1 1z"/>
											</svg>
										</span>
										<p id="hj">{t('why2')}</p>

								</Col>
					</Row>
				</Container>
			</section>
		
           
            </div>
            <div className="divsmp">
            <Container style={{paddingTop:'6vw',paddingBottom:'4vw'}}>
                <Row>
                    <Col xs="12"  xl="6" className="j1" style={{marginTop:'7vw'}}>   
                        <div style={{marginBottom:'26px'}}>
                            <p className="joinp" style={{color:'#1a6976'}}>{t('withus')}</p>

                            <h2 className="h2join">{t('amazing')}  <br/>{t('adventure')}  </h2>
                        </div> 
                        <div style={{marginBottom:'32px'}}>
                            <p className="joinp1" style={{paddingLeft:'0vw'}}>{t('booking')}</p>
                        </div>
                        
                    </Col>
                    <Col xs="12"  xl="6">
                        <img src={SDK} className="img-fluid" alt="hotelidh" />
                    </Col>
                </Row>
            </Container>
            </div>
            <div className="bac">
            <Container>
                <Row>
                    <Col lg="4" style={{padding:'1vw'}}>
                        <div style={{marginBottom:'10px'}}>
                            <p className="joinp" style={{color:'#861e12',marginBottom:'1rem'}}>{t('whole')}</p>
                            <h2 className="h2join" style={{marginBottom:'0vw'}}>{t('world')}</h2>
                        </div> 
                        <div style={{marginBottom:'10px',paddingLeft:'0px'}}>
                            <p className="joinp11">{t('with')}<br/>{t('100')}<br/><br/>{t('benefits')}</p>
                        </div>
                    </Col>
                    <Col  lg="4" >
                        <div>
                            <div className="ji">
                                                <div >
                                                                <div className="jfi">
                                                                    <img src={dl} alt="hotelidh" className="jimg" />
                                                                </div>
                                                </div>
                                                <div className="mlk" style={{textAlign:'center'}}>
                                                                <h3 className="mlh3" style={{}}>{t('agency')}</h3>
                                                                
                                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col  lg="4"  >
                        <div>
                            <div className="ji">
                                                <div >
                                                                <div className="jfi">
                                                                    <img src={dl1} alt="hotelidh" className="jimg" />
                                                                </div>
                                                </div>
                                                <div className="mlk" style={{textAlign:'center'}}>
                                                                <h3 className="mlh3">{t('over')}</h3>
                                                                
                                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col  lg="4"  >
                        <div>
                            <div className="ji">
                                                <div >
                                                                <div className="jfi">
                                                                    <img src={dl2} alt="hotelidh" className="jimg" />
                                                                </div>
                                                </div>
                                                <div className="mlk" style={{textAlign:'center'}}>
                                                                <h3 className="mlh3">{t('20')}</h3>
                                                                
                                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col  lg="4"    >
                        <div>
                            <div className="ji">
                                                <div >
                                                                <div className="jfi">
                                                                    <img src={dl3} alt="hotelidh" className="jimg" />
                                                                </div>
                                                </div>
                                                <div className="mlk" style={{textAlign:'center'}}>
                                                                <h3 className="mlh3">{t('200')}</h3>
                                                                
                                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col  lg="4" style={{marginTop:'-5vw'}} >
                        <div>
                            <div className="ji">
                                                <div >
                                                                <div className="jfi">
                                                                    <img src={dl33} alt="hotelidh" className="jimg" />
                                                                </div>
                                                </div>
                                                <div className="mlk" style={{textAlign:'center'}}>
                                                                <h3 className="mlh3">{t('customer')}</h3>
                                                                
                                                </div>
                            </div>
                        </div>
                    </Col>  
                </Row>
            </Container>
            </div>
            
            <NewFoot />
        </div>
    );
}

export default About;