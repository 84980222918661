import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import room from "../slide/ted4.png";
import billet from "../slide/bil.png";
import Sds from "../slide/ddrhhn.png";
import car from "../slide/car.png";
import SD from "../slide/taaa.png";
import Sds1 from "../slide/3a6.png";
import Header from "./home/Header";
import SubHeader from "./home/SubHeader";
import NewFoot from "./home/NewFoot";
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next'


function Services(){

        const { t, i18n } = useTranslation();
        const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        };
    return(
        
        <div>
            <SubHeader />
            <Header />
            <div className="Service1">
                <div className="ContactH1">
                    <div className="ContactH11">
                        <div className="ContactH111">
                            <span className="spanc">{t('whatwe')}</span>
                            <h2 className="h3c" style={{fontWeight:'400'}}>{t('fory')}</h2>
                        </div>

                    </div>

                </div>
                
          
            </div>
            <div id="srv" style={{backgroundColor:'#fafafa',position:'relative',zIndex:'100'}}>
          
          <Container className="scm" style={{position: 'relative',top: '-4vw'}}>
              <Row>
                  <Col className="prcol" xl="4" lg="12" style={{display:'flex', flexDirection:'column',paddingBottom:'50px'}}>
                      <div className="jfi">       
                          <img src={room}  alt="hotelidh" className="jimg"  /> 
                      </div>
                      <div className="divdimg">
                          <h2 className="h2s2">{t('accommodation')}</h2>
                          <p className="pservice2">{t('accommodation1')}<br/><br/>{t('accommodation2')}</p>
                      </div>
                  
                  </Col>

                  <Col className="prcol mb-4" xl="4" lg="12" style={{display:'flex', height:'fit-content', flexDirection:'column'}}>
                  <div className="jfi">   
                      <img src={billet}  alt="hotelidh" className="jimg"  /> 
                  </div>   
                      <div className="divdimg">
                          <h2 className="h2s2">{t('ticketing')}</h2>
                          <p className="pservice2">{t('ticketing1')}<br/>{t('ticketing2')}</p>
                          
                      </div>
                  
                  </Col>

                  <Col className="prcol" xl="4" lg="12" style={{display:'flex', flexDirection:'column',paddingBottom:'100px',paddingRight:'50px'}}>
                          <div className="jfi">   
                              <img src={Sds}  alt="hotelidh" className="jimg"  /> 
                          </div> 
                          <div className="divdimg">
                              <h2 className="h2s2">{t('package')}</h2>
                              <p className="pservice2">{t('package1')} </p>   
                          </div>
                      
                  </Col>
                  
                  <Col className="prcol" id="dtop" xl="4" lg="12" style={{display:'flex', flexDirection:'column',paddingBottom:'50px'}}>
                      <div className="jfi">
                          <img src={car}  alt="hotelidh" className="jimg" /> 
                      </div>
                      <div className="divdimg">
                          <h2 className="h2s2">{t('car')} </h2>
                          <p className="pservice2">{t('car1')} </p>
                      </div>
                  
                  </Col>

                  <Col className="prcol " id="ddtop" xl="4" lg="12" style={{display:'flex', flexDirection:'column', }}>
                      <div className="jfi">   
                          <img src={SD}  alt="hotelidh" className="jimg" /> 
                      </div>
                      <div className="divdimg">
                          <h2 className="h2s2"> {t('transfers')}</h2>
                          <p className="pservice2">{t('transfers1')}</p>
                      </div>
                  
                  </Col>

                  <Col className="prcol" id="dddtop" xl="4" lg="12" style={{display:'flex', flexDirection:'column',paddingBottom:'50px',paddingRight:'50px'}}>
                      <div className="jfi">     
                          <img src={Sds1}  alt="hotelidh" className="jimg"  /> 
                      </div>
                      <div className="divdimg">
                          <h2 className="h2s2">{t('visits')}</h2>
                          <p className="pservice2">{t('visits1')}</p>
                      </div>
                  
                  </Col>
              </Row>
          </Container>
            </div>
            <div id="srv1" className="join3divvn" >           
						<Container fluid  >
							<Row >
										< Col sm={12} style={{paddingTop:'10vw'}}>
											<div className="join3divpp" style={{display:'flex', flexDirection:'column'}}>
												
                                                <span className="join3span" style={{ color:'white',fontFamily:'Cormorant Garamond',textAlign:'center'}}>{t('jusnow')}</span>
											
												<p style={{color:'white',marginLeft:'2vw',fontSize:'18px',marginBottom:'1.5vw',textAlign:'center'}}>{t('jusnow1')}</p>
                                                

                                                <>
                                    <style type="text/css">
                                        {`
                                    .btn-flat {
                                    color: white;
                                   
                                    }

                                    .btn-xxl {
                                    padding: 1rem 1.5rem;
                                    font-size: 1.5rem;
                                    background-color:#772828;
                                    padding: 8px 40px;
                                    border-radius: 0px;
                                    margin: auto ;
                                    }

                                    `}
                                    </style>

                                    <Button style={{padding:'10px',margin:'auto', fontSize:'1rem', width:'35%',fontWeight:'600',letterSpacing:'1px'}} variant="flat" size="xxl"  >
                                        <a href="./Join" style={{fontSize:'1.1rem',fontWeight:'200'}} className="text-white">{t('jusnow154')}   </a>
                                    </Button>
                                    </>

											</div>
										</Col>
										
										
							</Row>
						</Container>

				</div>
            <NewFoot />
        </div>

    );
}

export default Services;