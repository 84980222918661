import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import SD from "../slide/tibi22.jpg";
import Contactf from "./Contactf";
import Header from "./home/Header";
import NewFoot from "./home/NewFoot";
import SubHeader from "./home/SubHeader";
import { useTranslation } from 'react-i18next'

function Contact(){
    const { t, i18n } = useTranslation();
    const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
    return(
        <div>
            <SubHeader />
            <Header />
            <div className="Contact1">
                <div className="ContactH1">
                    <div className="ContactH11">
                        <div className="ContactH111">
                            <span className="spanc">{t('Contact')} </span>
                            <h2 className="h3c" style={{fontWeight:'400'}}>{t('git')}</h2>
                        </div>

                    </div>

                </div>
                
          
            </div>
            <Container style={{backgroundSize:'cover'}}>
            <Row style={{position:'relative',margin:'0 auto'}}>
                <div className="cont22">
                    <Col xs="12"  xl="6" className="j1" style={{padding:'3vw'}}>   
                            <div style={{marginBottom:'26px'}}>
                                <p className="joinp" style={{color:'#1a536d'}}>{t('always')}</p>
                                <h2 className="h2join">{t('contactus')} </h2>
                            </div> 
                            <div style={{marginBottom:'32px'}}>
                                <p className="joinp1" style={{paddingLeft:'0vw'}}>{t('contactp')}<br/> <br/></p>
                            </div>
                            <div >
                            <>
                                <style type="text/css">
                                    {`
                                .btn-flat {
                                background-color: #c3bbaa;
                                color: white;
                                }

                                .btn-xxl {
                                padding: 1rem 1.5rem;
                                font-size: 1.5rem;
                                float: left;
                                


                                }
                                `}
                                </style>

                                <Button variant="flat" size="xxl" style={{}}>
                                    <a href="./Join" className="text-white ">{t('tojoin')}  </a>
                                </Button>
                            </> 

                           
                            </div>
                    </Col>
                    <Col xs="12"  xl="6" className="">

                        <img className="img-fluid" src={SD}  alt="hotelidh" />
                    </Col>

                </div>

            </Row>

            </Container>
            <div style={{backgroundColor:'#f7f6f5'}}>
        <Container  style={{paddingTop:'5vw', paddingBottom:'5vw'}}>
            <Row> 
                <Col  className="ContSp" sm={12} style={{display:'flex', flexDirection:'column'}}>
                    <h3 className="colh3">{t('getin')} </h3>
                    <p style={{textAlign:'left',fontSize:'16px'}}>
                    {t('tojoinp')}
                    <br/><br/></p>
                    
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" color="#c3bbaa" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mail"><rect width="20" height="16" x="2" y="4" rx="2"/><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"/></svg>
                        <br/> 
                        <p style={{paddingLeft:'1vw',fontFamily:' Poppins,sans-serif',fontSize:'14px',color:'#505050'}}>
                        {t('adresse')} <br/>
                        </p>
                        
                    </span>

                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" color="#c3bbaa" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mail"><rect width="20" height="16" x="2" y="4" rx="2"/><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"/></svg>
                       
                     <p style={{paddingLeft:'1vw',fontFamily:' Poppins,sans-serif',fontSize:'14px',color:'#505050'}}>
                     {t('adresse1')} 
                        </p>
                    </span>
                   
                    <span>
                    <svg xmlns="http://www.w3.org/2000/svg" color="#c3bbaa" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-map-pin"><path d="M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z"/><circle cx="12" cy="10" r="3"/></svg><br/> 
                        <p style={{paddingLeft:'1vw',fontFamily:' Poppins,sans-serif',fontSize:'14px',color:'#505050'}}>
                        {t('adresse2')}  
                            
                        </p>
                    </span>
                </Col>
                
            </Row>
        </Container>
            </div>
            <Contactf />
            <NewFoot />
        </div>
    );
}

export default Contact;