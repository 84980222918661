import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useTranslation } from 'react-i18next'

function NewFoot(){

    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };

    return(
        <div style={{backgroundColor:'#f7f6f5'}}>
            <Container fluid className="sdxc" style={{}}>
                <Row>
                    <Col xs="12" xl="6" className="footleft">
                        <Row className="foot">
                           
                            
                        <Col sm={6}  className="hy" style={{paddingLeft:'4vw',borderRight:'solid #505050 1px'}}>   
                                    <div style={{display:'flex' ,flexDirection:'row',marginBottom:'2vw'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg"  color="#c3bbaa" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-phone-call"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"/><path d="M14.05 2a9 9 0 0 1 8 7.94"/><path d="M14.05 6A5 5 0 0 1 18 10"/></svg> 
                                        <h3 style={{marginLeft:'1vw', fontWeight:'bold',color:' #861e12',fontFamily:'Cormorant Garamond'}}>{t('foot')}</h3>
                                
                                    </div>
                                    <div style={{marginBottom:'2vw'}}>
                                       
                                        <p className="pfpf">

                                            <a href="./services/#srv">{t('foot11')}</a>
                                            <br/> <br/>
                                            <a href="./services/#srv">{t('foot12')}</a>
                                            <br/> <br/>
                                            <a href="./services/#srv">{t('foot13')}</a>
                                            <br/><br/> 
                                            <a href="./services/#srv">{t('foot14')}</a>
                                            <br/><br/> 
                                            <a href="./services/#srv">{t('foot15')}</a>
                                            <br/><br/> 
                                            <a href="./services/#srv">{t('foot16')}</a>


                                        </p>
                                    </div>
                        </Col>
                        <Col sm={6}  className="hy" style={{paddingLeft:'4vw'}}>
                                    <div style={{display:'flex' ,flexDirection:'row',marginBottom:'2vw'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg"  color="#c3bbaa" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-home"><path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"/><polyline points="9 22 9 12 15 12 15 22"/></svg>  
                                        <h3 style={{marginLeft:'1vw', fontWeight:'bold',color:' #861e12',fontFamily:'Cormorant Garamond'}}>{t('foot1')}</h3>
                              
                                    </div>
                                    <div>
                                       
                                        <p className="pfpf">
                                               <a href="/">{t('foot31')} </a> 
                                            <br/> <br/> 
                                                <a href="/About">{t('foot32')} </a>
                                            <br/> <br/> 
                                            <a href="/Services">{t('foot33')} </a>
                                            <br/><br/> 
                                            <a href="/Join">{t('foot34')} </a>
                                            <br/><br/>
                                            <a href="/Contact">{t('foot35')} </a>
                                            
                                        </p>
                                    </div>
                        </Col>
                        <Col sm={6}  className="hy" style={{paddingLeft:'4vw',borderRight:'solid #505050 1px'}}>   
                                    <div style={{display:'flex' ,flexDirection:'row',marginBottom:'2vw'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg"  color="#c3bbaa" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-send"><path d="m22 2-7 20-4-9-9-4Z"/><path d="M22 2 11 13"/></svg>
                                     
                                     <h3 style={{marginLeft:'1vw', fontWeight:'bold', color:' #861e12',fontFamily:'Cormorant Garamond'}}>{t('foot2')}</h3>
                              
                                    </div>
                                    <div style={{marginBottom:'2vw'}}>
                                       
                                        <p className="pfpf" style={{fontSize:'16px',fontWeight:'600'}}>
                                            
                                            <a href="https://www.elcomparador-b2b.com/">{t('foot21')} </a>
                                            <br/> <br/>
                                            <a href="http://srv2.elcomparador-b2b.com/hotels">{t('foot22')}</a>

                                            <br/><br/>
                                            <a href="https://www.idhcorporate.com/corporate">{t('foot23')}</a>
                                           
                                            <br/><br/>
                                            <a href="https://test.groupidh.com/IdhNetwork">{t('foot24')}</a>
                                           
                                            <br/> <br/> 
                                            <a href="https://test.groupidh.com/">{t('foot25')}</a>
                                        
                                        </p>
                                    </div>
                        </Col>
                              
                        <Col sm={6}  className="hy" style={{paddingLeft:'4vw'}}>
                                    <div style={{display:'flex' ,flexDirection:'row',marginBottom:'2vw'}}>
                                        <svg xmlns="http://www.w3.org/2000/svg" color="#c3bbaa" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-user-check"><path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"/><circle cx="9" cy="7" r="4"/><polyline points="16 11 18 13 22 9"/></svg> <h3 style={{marginLeft:'1vw', fontWeight:'bold', color:' #861e12', fontFamily:'Cormorant Garamond'}}>{t('foot3')}</h3>
                              
                                    </div>
                                    <div className="spsv" style={{display:"flex", flexDirection:'row'}}>
                                       <Row>
                                            <Col sm={4} >
                                                <span >
                                                    <a href="https://www.instagram.com/idhtours/?hl=fr">
                                                        <svg style={{ marginBottom:'2vw'}} xmlns="http://www.w3.org/2000/svg" color="#505050" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-instagram"><rect width="20" height="20" x="2" y="2" rx="5" ry="5"/><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"/><line x1="17.5" x2="17.51" y1="6.5" y2="6.5"/></svg>
                                                    </a>
                                                    <a href="https://www.facebook.com/IDHgroupreseau/?ref=pages_you_manage">
                                                        <svg style={{ marginBottom:'2vw'}} xmlns="http://www.w3.org/2000/svg" color="#505050" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-facebook"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"/></svg>
                                                    </a>
                                                    
                                                </span>    
                                            </Col>
                                            
                                           

                                            <Col  sm={4}>
                                                <span>
                                                    <a href="https://es.linkedin.com/company/idhgroup">  
                                                        <svg style={{ marginBottom:'2vw'}} xmlns="http://www.w3.org/2000/svg" color="#505050" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-linkedin"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"/><rect width="4" height="12" x="2" y="9"/><circle cx="4" cy="4" r="2"/></svg>
                                                    </a>
                                                    <a href="http://groupidh.com/">
                                                        <svg xmlns="http://www.w3.org/2000/svg" color="#505050" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-link"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"/><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"/></svg>
                                                    </a>
                                                </span> 
                                            </Col>

                                          
                                            
                                        </Row> 
                                    </div>
                        </Col>
                                
                        <Col sm={12} className="ert">
                                     <p className='copy' >© 2009-2023 Hotelidh __ All rights reserved </p>
										
                        </Col>
                            
                        </Row>
                    </Col>

                    <Col xs="12" xl="6" >
                        <div className="imgfoot">

                        </div>
                    </Col>
                    
                </Row>
              
            </Container>
        </div>
    );
}

export default NewFoot;