import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SD from "../slide/652.png";
import Button from 'react-bootstrap/Button';
import Header from "./home/Header";
import SubHeader from "./home/SubHeader";
import NewFoot from "./home/NewFoot";
import sdk from "../slide/97.png";

import { useTranslation } from 'react-i18next'



function Join(){
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
    return(
        <div>
            <SubHeader />
            <Header />
            <div className="Join1">
                <div className="ContactH1">
                    <div className="ContactH11">
                        <div className="ContactH111">
                            <span className="spanc"> {t('HotelIdh')}</span>
                            <h2 className="h3c">{t('HotelIdhn')}</h2>
                        </div>

                    </div>

                </div>
                
          
            </div>
            <div className="qsd11">
            <Container style={{paddingTop:'6vw',paddingBottom:'4vw'}}>
                <Row>
                    <Col xs="12"  xl="6">
                        <img className="img-fluid" src={SD}  alt="hotelidh" />
                    </Col>
                    <Col xs="12"  xl="6" className="j1" style={{ marginTop:'1vw'}}>   
                        <div style={{marginBottom:'26px'}}>
                            <p className="joinp" style={{color:'#1a6976',marginBottom:'1rem'}}>{t('travela')}</p>

                            <h2 className="h2join">{t('travela1')} <br/>{t('travela2')}</h2>
                       
                        </div> 
                        <div style={{marginBottom:'32px',float:'right',paddingLeft:'0vw', display:'flex',flexDirection:'row'}}>
                            <p className="joinp1" style={{padding:'1vw'}}>
                                {t('travelgate')}
                            <br/><br/>
                                {t('travelgate1')} 
                            </p>

                           
                        </div>
                        <div style={{float:'right'}}>
                        <>
                                    <style type="text/css">
                                        {`
                                    .btn-flat {
                                    color: white;
                                   
                                    }

                                    .btn-xxl {
                                    padding: 1rem 1.5rem;
                          
                                    background-color:#772828;
                                    padding: 8px 40px;
                                    border-radius: 0px;
                                    font-family: 'Cormorant Garamond';
                                    
                                    }

                                    `}
                                    </style>

                                    <Button id="btnid" style={{margin:'auto', fontSize:'0.9rem',fontWeight:'100',letterSpacing:'1.5px',border:'none'}}   size="xxl"  >
                                       <a href="SignUp">{t('Sign')}</a>
                                    </Button>
                                </>
                                </div>
                    </Col>
                </Row>
            </Container>
            </div>
                <div className="join3div">           
						<Container fluid  >
							<Row >
										< Col sm={12} style={{}}>
											<div className="join3divp">
												
												<span className="join3span">{t('plus')}</span>
												<p className="llnn" style={{color:'#1a536d',marginLeft:'2vw',fontSize:'21px'}}>
                                                {t('plus1')}
												</p>

											</div>
										</Col>
										
										
							</Row>
						</Container>

				</div>
                <div className="qsd111">
            <Container style={{paddingTop:'6vw',paddingBottom:'10vw'}}>
                <Row>
                    <Col  xs="12"  xl="6"  className="j1" style={{ marginTop:'1vw'}}>   
                        <div style={{marginBottom:'26px'}}>
                            <p className="joinp" style={{color:'#1a6976',marginBottom:'1rem'}}>{t('million')}</p>

                            <h2 className="h2join">{t('million1')} <br/>{t('million2')}</h2>
                        
                        </div> 
                        <div style={{marginBottom:'32px',float:'right',paddingLeft:'0vw', display:'flex',flexDirection:'row'}}>
                            <p className="joinp1" style={{padding:'1vw'}}>{t('million3')}<br/></p>
                        </div>
                        <div style={{float:'left'}}>
                        <>
                                    <style type="text/css">
                                        {`
                                    .btn-flat {
                                    color: white;
                                   
                                    }

                                    .btn-xxl {
                                    padding: 1rem 1.5rem;
                          
                                    background-color:#772828;
                                    padding: 8px 40px;
                                    border-radius: 0px;
                                    font-family: 'Cormorant Garamond';
                                    
                                    }

                                    `}
                                    </style>

                                    <Button id="btnid" style={{margin:'auto', fontSize:'0.9rem',fontWeight:'100',letterSpacing:'1.5px',border:'none'}}   size="xxl"  >
                                       <a href="SignUp">{t('Sign')}</a>
                                    </Button>
                                </>
                                </div>
                        
                    </Col>
                    <Col  xs="12"  xl="6" className="mt-2">
                        <img src={sdk} className="img-fluid"  alt="hotelidh" />
                    </Col>
                </Row>
            </Container>
                </div>
                <div style={{}}>
                <div>
                    <h1 style={{fontWeight:'400',color:'#1e2031', textAlign:'center',fontSize:'62px',fontFamily:'"Cormorant Garamond"',paddingTop:'5vw',paddingBottom:'2vw'}}>{t('howtojoin')}  </h1>  
                    <p style={{textAlign:'center'}}>{t('howtojoin1')} <br/> {t('howtojoin2')} </p>
                </div>  
        <Container  style={{paddingTop:'5vw', paddingBottom:'5vw'}}>
            <Row>
                <Col className="ContSp" sm={4} style={{display:'flex', flexDirection:'column',paddingLeft:'4vw'}}>
                    <h3 className="colh3">{t('01')}   </h3>
                    <h3 className="colh3" style={{color:'#1a536d'}}>{t('account')}</h3>
                    <p style={{marginBottom:'1rem',textAlign:'left',fontSize:'16px'}}>{t('account1')}</p>
                    
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" style={{marginTopTop:'4px'}} color="#861e12" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg><br/> 
                        <p style={{marginBottom:'1rem',paddingLeft:'1vw',fontFamily:' Poppins,sans-serif',fontSize:'14px',color:'#505050'}}>{t('account2')}</p>
                    </span>
                    <span >
                        
                        <svg xmlns="http://www.w3.org/2000/svg" style={{marginTopTop:"4px"}} color="#861e12" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg><br/> 
                        <br/> 
                        <p style={{marginBottom:'1rem', textAlign:'left', paddingLeft:'1vw',fontFamily:' Poppins,sans-serif',fontSize:'14px',color:'#505050'}}>{t('account3')}</p> 
                   
                    </span>
                    

                </Col>

                <Col className="ContSp" sm={4} style={{display:'flex', flexDirection:'column',paddingLeft:'4vw'}}>
                    <h3 className="colh3">{t('02')}  </h3>
                    <h3 className="colh3" style={{color:'#1a536d'}}>{t('request')}   </h3>
                    <p style={{marginBottom:'1rem',textAlign:'left',fontSize:'16px'}}>{t('request1')} <br/> {t('request2')}</p>
                    
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" style={{marginTopTop:'4px'}} color="#861e12" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg><br/> 
                        <p style={{paddingLeft:'1vw',fontFamily:' Poppins,sans-serif',fontSize:'14px',color:'#505050'}}>
                        {t('request3')}
                        </p>
                    </span>
                    <span >
                        
                        <svg xmlns="http://www.w3.org/2000/svg" style={{marginTopTop:"4px"}} color="#861e12" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg><br/> 
                        <br/> 
                        <p style={{textAlign:'left', paddingLeft:'1vw',fontFamily:' Poppins,sans-serif',fontSize:'14px',color:'#505050'}}>
                        {t('request4')}
                        
                        </p> 
                   
                    </span>
                </Col>

                <Col  className="ContSp" sm={4} style={{display:'flex', flexDirection:'column',paddingLeft:'4vw'}}>
                    <h3 className="colh3"> {t('03')} </h3>
                    <h3 className="colh3" style={{color:'#1a536d'}}>{t('activation')}</h3>
                    <p style={{marginBottom:'1rem',textAlign:'left',fontSize:'16px'}}>{t('activation1')}</p>
                    
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" style={{marginTopTop:'4px'}} color="#861e12" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg><br/> 
                        <p style={{paddingLeft:'1vw',fontFamily:' Poppins,sans-serif',fontSize:'14px',color:'#505050'}}>
                        
                        {t('activation2')}   
                        </p>
                    </span>
                    <span >
                        
                        <svg xmlns="http://www.w3.org/2000/svg" style={{marginTopTop:"4px"}} color="#861e12" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check"><path d="M20 6 9 17l-5-5"/></svg><br/> 
                        <br/> 
                        <p style={{textAlign:'left', paddingLeft:'1vw',fontFamily:' Poppins,sans-serif',fontSize:'14px',color:'#505050'}}> 
                        
                        {t('activation3')} 
                        </p> 
                   
                    </span>
                </Col>
                
            </Row>
        </Container>
        </div>
           
            <NewFoot />
        </div>
    );
}

export default Join;