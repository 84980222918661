
import React , {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Form } from "react-bootstrap";
import { useTranslation } from 'react-i18next'



function Contactf(){
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };

    const [nom, setNom]=useState("")
    const [email, setEmail]=useState("")
    const [message, setMessage]=useState("")
    const navigate =useNavigate();

    async function signUp()
    {
       
        let item={nom,email,message}
        console.warn(item)

        let result = await fetch("http://localhost:8000/api/register",{
            method:'POST',
            body:JSON.stringify(item),
            headers:{
                "Content-Type":'application/json',
                "Accept":'application/json'
            }
        })

        result =await result.json()
     
        localStorage.setItem("user-info",JSON.stringify(result))
        navigate('/GetInfo');
       
    }


    return (
                <div>
                    <h1 style={{color:'#1e2031', textAlign:'center',fontSize:'62px',fontFamily:'"Cormorant Garamond"',paddingTop:'5vw',paddingBottom:'2vw'}}>{t('write')}  </h1>  
                    <p style={{textAlign:'center'}}>{t('write1')} </p>
                <div className='fvf'>  
                <Container className='form_sub' id='contactid'>
                        
                          

                        <Row >
                            <Col sm={12}  style={{backgroundColor:''}}>
                                <Form className='formdiv' style={{}}>
                                    <Form.Group  className="mb-3" controlId="exampleForm.ControlInput1">
                                      
                                        <Form.Control  type="text" placeholder="John Doe" onChange={(e)=>setNom(e.target.value)} value={nom} style={{marginBottom:"2vw",padding:'20px'}} />
                                            
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                      
                                        <Form.Control  type="text" placeholder="+33 7 74 51 31 32" onChange={(e)=>setNom(e.target.value)} value={nom} style={{marginBottom:"2vw",padding:'20px'}} />
                                
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" >
                                        
                                        <Form.Control type="email" placeholder="nom@example.com" onChange={(e)=>setEmail(e.target.value)} value={email} style={{marginBottom:"2vw",padding:'20px'}}/>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        
                                        <Form.Control  as="textarea" rows={10} placeholder='Your message...' onChange={(e)=>setMessage(e.target.value)}  value={message} style={{marginBottom:"2vw",padding:'30px'}}/>
                                    </Form.Group>

                                    <>
                                    <style type="text/css">
                                        {`
                                    .btn-flat {
                        
                                    color: white;
                                    float:left;
                                 
                                    }

                                    .btn-xxl {
                                    padding: 1rem 1.5rem;
                                    font-size: 1.5rem;
                                    background-color:#772828;
                                    padding: 8px 40px;
                                    border-radius: 0px;
                                    }
                                    `}
                                    </style>

                                    <Button style={{padding:'10px',width:'100%'}} variant="flat" size="xxl" onClick={signUp} >
                                     {t('Send')}
                                    </Button>
                                    </>
                                </Form>
                            </Col>


                            
                        </Row>
                </Container>
                </div>
                </div>
   
                
      );
}



export default Contactf;