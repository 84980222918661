import { Nav } from 'react-bootstrap';
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../../slide/imagessss.png";
import Navbar from 'react-bootstrap/Navbar';

import { useTranslation } from 'react-i18next'


function Header(){
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };
    return(
       
       
                        <div className='subhead' >
                            <Container fluid >
                                <Row style={{}} className=''>
                                
                                    <Col  className='p-0' style={{margin:'auto'}}>
                                    
                                                <Navbar expand="xl" className="bg-body-tertiary p-0 ml-3" >
                                                    <Container fluid style={{backgroundColor:'white'}} className=''>
                                                        <Navbar.Brand href="./" style={{paddingRight:'2vw',paddingTop:'0vw',marginLeft:'4vw'}}> <img src={Logo} alt="Logo" style={{}} /></Navbar.Brand>
                                                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                                        <Navbar.Collapse id="basic-navbar-nav" style={{}}>
                                                       
                                                        <Nav className="me-auto" style={{fontWeight:'550'}}>
                                                                    <Nav.Link href="/"  id='link1'>{t('home')}</Nav.Link>
                                                                    <Nav.Link href="/About"  id='link2'>{t('home1')}</Nav.Link>
                                                                    <Nav.Link href="/Services"  id='link3'>{t('home2')}</Nav.Link>
                                                                    <Nav.Link href="/Join"  id='link4'>{t('home3')}</Nav.Link>
                                                                    <Nav.Link href="/Contact"  id='link5' > {t('home4')}</Nav.Link>
                                                                    <Nav.Link href="/SignUp"  id='link6' >{t('home5')}  </Nav.Link>
                                                                    <Nav.Link href="/Agency"  id='link6a' > {t('home6')}</Nav.Link>
                                                                    
                                                        </Nav>
                                                        </Navbar.Collapse>
                                                    </Container>
                                                </Navbar>
                                                



                                    </Col>

                                </Row>
                            </Container>
                        </div>
       
       
        

       
    )
}

export default Header; 