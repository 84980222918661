// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LangFr from './translations/fr.json';
import LangEn from './translations/en.json';
import LangEs from './translations/es.json';

const getStoredLanguage = () => {
  return localStorage.getItem('i18nLng') || 'en'; // Langue par défaut
};

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: LangEn,
      fr: LangFr,
      es: LangEs,
    },
    lng: getStoredLanguage(),
    fallbackLng: 'en', // Changez cela selon vos préférences
    interpolation: {
      escapeValue: false,
    },
  });

// Fonction pour changer la langue
export const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
  localStorage.setItem('i18nLng', lng); // Enregistre la langue
};

export default i18n;